
import { Vue, Component } from "vue-property-decorator";
import { ObjectDataSource } from "@/data/Object/ObjecDatatSource";
import ResumeEditor from "@/components/resume-editor/index.vue"
@Component({
  components:{ResumeEditor}
})
export default class Home extends Vue {
  dataSource: ObjectDataSource = new ObjectDataSource({
    id: +this.$route.params.resumeId,
    config: {},
    className: "resume",
  });



  doctorObjectDataSource: ObjectDataSource = new ObjectDataSource({
    id: +this.$route.params.id,
    config: {},
    className: "doctor",
  });
  loaded: boolean = false;

  get caption(): any {
    return this.dataSource.model?.caption;
  }

  get breadcrumbs() {
    return [
      {
        text: "Главная",
        to: "/",
      },
      {
        text: "Врачи",
        to: "/manage/doctors/",
        exact: true,
      },
      {
        text: this.doctorObjectDataSource.model?.caption,
        to: `/manage/doctors/${+this.$route.params.id}`,
        exact: true,
      },
      {
        text: "Резюме",
        to: `/manage/doctors/${+this.$route.params.id}/resume`,
        exact: true,
      },
      {
        text: this.caption,
        disabled: true,
      },
    ];
  }

  async created() {
    await this.doctorObjectDataSource.get();
    this.loaded = true;
  }
}
