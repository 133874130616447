
import {Vue, Component, Prop} from 'vue-property-decorator';
import {ListDataSource} from "@/data/List/ListDataSource";
import {mdiArrowAll, mdiPlus, mdiTrashCan} from "@mdi/js";
import metaData from "./metaData.json"
import {instaceStates} from "@/cms-services/consts";
import {ObjectDataSource} from "@/data/Object/ObjecDatatSource";
@Component({})
export default class sectionItemEditor extends Vue{
  @Prop() section!:any
  @Prop() information!:any
  @Prop() resume!:any
  @Prop() personDataSource!:ObjectDataSource
  $message: any;
  icons:any = {
    plus:mdiPlus,
    trash:mdiTrashCan,
    arrow: mdiArrowAll
  }

  dataSource:ListDataSource = new ListDataSource({
    className:  this.information.className + "Item",
    config:{},
    items:this.section.items
  })


  async add(){
    await this.dataSource.add({
      resumeId: this.resume.id,
      [this.information.className + "Id"]: this.section.id,
      caption:"Новый элемент " + this.dataSource.items.length
    }, true);

    this.$message("Элемент успешно добавлен");
  }

  created(){
    const fieldName = ["professionalAreaSection", "skillSection", "interestsSection"].includes(this.information.className)? "resume": this.information.className.replace("Section","");
    this.dataSource.metadata = {
      properties: metaData[fieldName]
    }
  }
}
